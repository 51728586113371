<template>
    <div class="page-not-found">
        <p>404</p>
        <p>Not Found!</p>
    </div>
</template>

<script>
export default {
    name: "NotFount",
    data() {
        return {
            meta: {
                title: '404'
            }
        }
    },
    created() { },
    methods: { }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";
.page-not-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    flex-direction: column;
    padding-bottom: 100px;
    p {
        text-align: center;
    }
}
</style>


